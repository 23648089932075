/**
 * 判斷是否微信
 */
export function isWeiXin () {
  let ua = window.navigator.userAgent.toLowerCase()
  /* eslint-disable */
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}

/**
 * url编码
 */
export function urlencode (str) {  
  str = (str + '').toString();   
  return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').  
  replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');  
}

/**
 * 检测身份证号
 */
 export function testIdCard (idCard) {
  const idCardReg = new RegExp(/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/)

  return idCardReg.test(idCard)
}

/**
 * 检测手机号
 */
 export function testPhone (phone) {
  const phoneReg = new RegExp(/^1(3[0-9]|4[57]|5[0-35-9]|6[0-9]|7[01356789]|8[0-9]|9[0-9])\d{8}$/)

  return phoneReg.test(phone)
}