<!--  -->
<template>
  <div class='home-view' v-loading="loading">
    <div class="home-header">
      <!-- <img src="../../assets/img/dingbu.png" alt=""> -->
      <div class="login round">
        <login-view v-if="token == ''"></login-view>
        <!-- <div class="wx-code">
          <img src="../../assets/img/code/wx.png" alt="">
          <span>手机学习</span>
        </div> -->
        <div class="hide-login" v-else>
          <img :src="userInfo.img" alt="">
          <p>
            <span>{{userInfo.xingming}}</span>
            <span>{{userInfo.phone}}</span>
          </p>
          <div>
            <div @click="handleLink('Xuanke')">
              <span>课程学习</span>
              <img src="../../assets/img/study.png" alt="">
            </div>
            <div @click="handleLink('User')">
              <span>相片更换</span>
              <img src="../../assets/img/photo.png" alt="">
            </div>
            <div @click="handleLink('Fapiao')">
              <span>开具发票</span>
              <img src="../../assets/img/fapiao (2).png" alt="">
            </div>
            <div @click="handleQuit()">
              <span>退出登录</span>
              <img src="../../assets/img/quit.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-news" v-if="new_arr.length > 0">
      <div>
        <img src="../../assets/img/guangbo.png" alt="">
        <el-carousel height="25px" direction="vertical" :autoplay="true" indicator-position="none" :interval="2500">
          <el-carousel-item v-for="(item, index) in new_arr" :key="index">
            <div class="swiper" @click="handleNews(item.id)">
              <span>{{item.title}}</span>
              <div>{{item.add_time}}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="home-content">
      <keep-alive>
        <router-view v-if='$route.meta.keepAlive'></router-view>
      </keep-alive>
      <router-view v-if='!$route.meta.keepAlive'></router-view>
    </div>

    <div class="home-side">
      <div class="service-img">
        <img src="../../assets/img/dianhua.png" alt="">
        <span>客服电话</span>
        <div class="popup service">
          <span class="popup-title">客服电话</span>
          <div>400-811-5865转2</div>
        </div>
      </div>
      <div class="wechat-btn-img">
        <img src="../../assets/img/dianhu.png" alt="">
        <span>手机学习</span>
        <div class="popup wechat-btn">
          <span class="popup-title">手机学习</span>
          <div>
            <img src="../../assets/img/code/study-h5.png" />
          </div>
        </div>
      </div>
      <div class="upload-img">
        <img src="../../assets/img/weiixn.png" alt="">
        <span>微信客服</span>
        <div class="popup upload">
          <span class="popup-title">微信客服</span>
          <div>
            <img src="../../assets/img/code/kefu.jpg" />
          </div>
        </div>
      </div>
      <div class="issue-img">
        <img src="../../assets/img/issue.png" alt="">
        <span>常见问题</span>
        <div class="popup issue">
          <span class="popup-title">常见问题</span>
          <div>
            <div v-for="(item, index) in list" :key="index" @click="$router.push({name: 'IssueInfo',query: {id: item.id}})">{{index+1}}.{{item.title}}</div>
            <div class="more" @click="$router.push('IssueList')">查看更多</div>
          </div>
        </div>
      </div>
      <!-- <div class="upload-img">
        <img src="../../assets/img/weiixn.png" alt="">
        <span>学习指南</span>
        <div class="popup upload">
          <span class="popup-title">学习指南</span>
          <div>
            <img src="../../assets/img/code/xiaoqi.png" />
          </div>
        </div>
      </div> -->
    </div>

    <div class="home-bottom">
      <div>
        <div class="hb-content">
          <div>客服电话：<span>400-811-5865转2</span></div>
          <div>湘ICP备18002586号-1</div>
          <div>版权所有：长沙市湘源医药食品职业培训学校</div>
        </div>
        <div class="hb-code">
          <div>
            <div>
              <img src="../../assets/img/code/zhongqi.png" alt="">
            </div>
            <span>湘源教育陶老师</span>
          </div>
          <div>
            <div>
              <img src="../../assets/img/code/xiaoqi.png" alt="">
            </div>
            <span>湘源教育肖老师</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/home.js'
import MApi from '@/api/my.js' 
import LoginView from  './main/login.vue'

export default {
  components: {
    LoginView
  },
  data() {
    return {
      new_arr: [],
      loading: true,
      userInfo: '',
      list: [],
    };
  },
  computed: {
    token: {
      get () { return this.$store.state.token },
      set (val) { this.$store.commit('setToken', val) }
    }
  },
  watch: {
    '$route.name' () {
      this.getLoginType()
    }
  },
  created() {
    this.token = localStorage.getItem('token') || ''
    this.getConsult()
    this.getLoginType()
    setTimeout(() => {
      this.loading = false
    }, 500)
    this.getNewsArr()
    if (window.location.href && window.location.href.indexOf('applyInfo') != -1) {
      let search = window.location.href.split('?')[1]
      if (search.indexOf('&') != -1) {
        search = search.split('&')
        for (let i in search) {
          if (search[i].indexOf('applyInfo') != -1) {
            search = search[i]
          }
        }
      }
      if(search.indexOf('applyInfo') != -1) {
        let options = {
          applyInfo: search.split("=")[1],
          user_type: 1
        }
        this.otherLogin(options)
      }
    }
  },
  mounted() {
    this.loading = false
  },
  //方法集合
  methods: {
    getLoginType () {
      let userInfo = localStorage.getItem("userInfo") || ''
      if (userInfo) {
        this.userInfo = JSON.parse(userInfo)
      } else {
        this.userInfo = ''
      }
    },
    handleLink (url) {
      this.$router.push({name: url})
    },
    handleQuit () {
      Api.loginOut({})
      localStorage.setItem("userInfo", '')
      localStorage.setItem("token", '')
      localStorage.setItem("year", '')
      this.$store.commit('setToken', '')
      this.token = localStorage.getItem('token') || ''
      window.location.href = window.location.protocol + '//' + window.location.host
    },
    getNewsArr () {
      Api.getNewsArr({
        page: 0,
        status: '新闻'
      }).then(res => {
        this.new_arr = res.data.data
      })
    },
    getConsult () {
      MApi.getConsult({
        page: 1,
        limit: 10,
        status: '常见问题'
      }).then(res => {
        if (res.data.code == 1) {
          this.list = res.data.data
          this.list.forEach(item => {
            item.describe = item.describe ? item.describe.slice(0, 30) + '...' : item.describe
            item.years = item.add_time.split(" ")[0].split("-")
          })
        } else {
          this.$message.error("新闻数据获取失败")
        }
      })
    },
    handleNews (id) {
      if (this.$route.name != 'News') {
        this.$router.push({name: 'News', query: {id: id}})
      } else if (this.$route.query.id != id){
        this.$router.replace({name: 'News', query: {id: id}})
      }
    },
    otherLogin (form) {
      Api.otherLogin({
        applyInfo: form.applyInfo,
        user_type: form.user_type
      }).then(res => {
        if (res.data.code == 1) {
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('year', res.data.year)
          this.$store.commit('setToken', res.data.token)
          this.getZyRoot(res.data.year)
        } else {
          this.$message.error('登录失败')
        }
      })
    },
    getZyRoot (year) {
      MApi.getZyRoot({}).then(res => {
        if (res.data.code == 1) {
          localStorage.setItem('is_ok', res.data.is_ok)
          this.getUserInfo(year, res.data.is_ok)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getUserInfo (year, is_ok) {
      MApi.getUserInfo({year: year}).then(res => {
        if (res.status == 200) {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          if (is_ok == '1') {
            if (res.data.xuanke == 1) {
              this.$router.push({name: 'Xuanke'})
            } else {
              this.$router.push({name: 'Gouke'})
            }
          } else {
            this.$router.push({name: 'Gongxu'})
          }
        } else {
          this.$message.error("用户信息获取失败")
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
.home-view {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  position: relative;
  padding-bottom: 40px;
  .home-header {
    width: 100%;
    background-image: url('../../assets/img/dingbu.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    padding: 10px 30px;
    display: flex;
    justify-content: flex-end;
    > img {
      width: 100%;
    }
    > .login {
      // width: 28%;
      // position: absolute;
      // top: 10px;
      // right: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: rgba(000, 000, 000, 0.3);
      box-sizing: border-box;
      position: relative;
      padding: 0 50px;
      margin-right: 100px;
      .wx-code {
        img {
          width: 180px;
          // margin-top: 20px;
        }
        span {
          display: block;
          text-align: center;
          color: #fff;
          font-weight: bold;
          font-size: 22px;
        }
      }
      .hide-login {
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        zoom: 0.8;
        > img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          overflow: hidden;
          margin: 0 auto;
          display: block;
        }
        p {
          margin: 15px auto 0;
          color: #fff;
          font-weight: bold;
          span {
            font-size: 24px;
            display: block;
            text-align: center;
            margin: 0 auto;
          }
        }
        > div {
          width: 300px;
          display: flex;
          flex-wrap: wrap;
          > div {
            margin-top: 15px;
            width: 50%;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
              width: 80px;
              height: 80px;
              margin-top: 5px;
            }
            span {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .home-news {
    background-color: #EDF6FF;
    width: 100%;
    padding: 10px 0;
    > div {
      width: 72%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 35px;
        height: 35px;
      }
      > div {
        flex: 1;
      }
      .swiper {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          margin: 0 25px 0 10px;
          font-size: 18px;
          font-weight: bold;
          color: red;
        }
        div {
          font-size: 14px;
          color: #444444;
        }
      }
    }
  }

  .home-content {
    // min-width: 1400px;
    // max-width: 70%;
    width: 75%;
    flex: 1;
    margin: 0 auto;
    padding: 20px 0;
  }


  /* 针对屏幕宽度小于600px的样式 */
  // @media screen and (max-width: 900px) {
  //   .home-content {
  //     min-width: 800px;
  //     max-width: 70%;
  //   }
  // }

  .home-side {
    position: fixed;
    top: 60%;
    right: 20px;
    transform: translate(0, -50%);
    > div {
      border-radius: 5px;
      margin-bottom: 10px;
      background-color: #fff;
      padding: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      img {
        width: 60px;
      }
      > span {
        font-size: 16px;
        margin-top: 5px;
        white-space: nowrap;
      }
      .popup {
        display: none;
        position: absolute;
        top: 0; 
        left: 0;
        background-color: @default;
        padding: 0 10px;
        border-radius: 5px;
        transform: translate(-101.5%, 0);
        cursor: pointer;
        > div {
          white-space: nowrap;
          padding: 15px 10px 10px;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          img {
            width: 145px;
            height: 145px;
          }
        }
        .popup-title {
          border-bottom: 1px solid #fff;
          padding: 10px 0;
          color: #fff;
          text-indent: 20px;
          width: 100%;
          display: inline-block;
          text-align: left;
          font-size: 18px;
        }
      }
      .issue {
        > div {
          div {
            padding-bottom: 5px;
            border-bottom: 1px solid rgb(206, 204, 204);
            margin-bottom: 5px;
            font-size: 15px;
            color: #f3f3f3;
            font-weight: normal;
          }
          .more {
            text-align: center;
            border-bottom: none;
            margin-top: 10px;
            margin-bottom: 0;
          }
        }
      }
      // .service {
      //   top: 116px;
      //   transform: translate(-101.5%, 0);
      // }
      // .wechat-btn {
      //   top: 50%;
      //   transform: translate(-101.5%, -50%);
      // }
      // .upload {
      //   top: 100%;
      //   transform: translate(-101.5%, -100%);
      // }
    }
    > div:last-child {
      margin-bottom: 0;
      .popup {
        bottom: 0;
        top: auto;
      }
    }
    .service-img:hover .service {
      display: block;
    }
    .wechat-btn-img:hover .wechat-btn {
      display: block;
    }
    .upload-img:hover .upload {
      display: block;
    }
    .issue-img:hover .issue {
      display: block;
    }
    .sidebar .service:hover {
      display: block;
    }
    .sidebar .wechat-btn:hover {
      display: block;
    }
    .sidebar .upload:hover {
      display: block;
    }
    .sidebar .issue:hover {
      display: block;
    }
  }
  
  .home-bottom {
    width: 85%;
    padding: 20px 0 10px;
    margin: 0 auto;
    background-color: #09B38A;
    > div {
      width: 85%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .hb-content {
        color: #fff;
        text-align: left;
        div {
          margin-bottom: 3px;
          font-size: 20px;
          color: #e3e3e3;
          span {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
      .hb-code {
        display: flex;
        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          div {
            margin: 10px 0 10px;
            width: 85px;
            height: 85px;
            background-color: #fff;
            padding: 4px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          span {
            color: #e3e3e3;
            font-size: 12px;
          }
        }
        > div:first-child {
          margin-right: 30px;
        }
      }
    }
  }
}
</style>