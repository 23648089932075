<!--  -->
<template>
  <div class='login-view' :style="{paddingTop: is_need == 1 ? '20px' : '40px'}">
    <div>
      <h3>继续教育入口</h3>
      <div class="placeholder">
        <span>(选课、交费、学习)</span>
      </div>
    </div>

    <div class="login-content">
      <div>
        <img src="../../../assets/img/shenfenzheng.png" alt="">
        <input type="text" placeholder="手机号/身份证号码" v-model="form.card" maxlength="18" @change="getNeedCode">
        <span v-if="card.show">*{{card.msg}}</span>
      </div>

      <div class="hide">
        <input type="password" v-model="form.card" maxlength="18">
      </div>

      <div>
        <img src="../../../assets/img/nainfen.png" alt="">
        <el-select v-model="form.year" placeholder="选择年份" class="picker">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <span v-if="year.show">*{{year.msg}}</span>
      </div>

      <div v-if="is_need == 1">
        <img src="../../../assets/img/duanxin.png" alt="">
        <input type="text" placeholder="验证码" v-model="form.code" maxlength="18">
        <div @click="getCode">{{codeMsg}}</div>
        <span v-if="code.show">*{{code.msg}}</span>
      </div>

    </div>

    <div class="pricavy">
      <p v-if="checked" class="checked" @click="checked = !checked"><i class="el-icon-check"></i></p>
      <p v-else  @click="checked = !checked"></p>
      <div>我已阅读并同意<span @click="getPrivacy(1)">《用户协议》</span>和<span @click="getPrivacy(2)">《隐私协议》</span>。</div>
    </div>

    <div class="btn">
      <el-button round type="success" class="self-button--success" style="width: 280px" @click="onSubmit">登录</el-button>
    </div>
    <div class="hint">
      遇到问题不会操作？
      <span @click="handleLink">操作手册</span>
    </div>
  </div>
</template>

<script>
import Api from '@/api/home.js' 
import MApi from '@/api/my.js' 
import { testPhone, testIdCard } from '@/utils'

export default {
  components: {},
  data() {
    return {
      options: [],
      time: 59,
      codeMsg: '获取验证码',
      is_need: 1,
      form: {
        card: '',
        year: '',
        code: ''
      },
      card: {
        show: false,
        msg: '请填写身份证号码',
      },
      year: {
        show: false,
        msg: '请选择年份'
      },
      code: {
        show: false,
        msg: '请输入验证码'
      },
      checked: false
    };
  },
  computed: {},
  watch: {},
  created() {
    this.form.card = localStorage.getItem("card") || ''
    this.getYears()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    getYears () {
      MApi.getYears().then(res => {
        this.options = res.data.data.map(item => {
          return {
            label: item,
            value: item
          }
        });
      })
    },
    getNeedCode () {
      Api.getNeedCode({card: this.form.card}).then(res => {
        if (res.data.code == 1) {
          this.is_need = res.data.is_need
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getCode () {
      if (this.form.card == '') {
        this.card.show = true
        this.card.msg = '请填写手机号/身份证号码'
        return
      }
      if (this.form.card.length < 12 && !testPhone(this.form.card)) {
        this.card.show = true
        this.card.msg = '请输入正确的手机号/身份证号码'
        return
      }
      if (this.form.card.length > 12 && !testIdCard(this.form.card)) {
        this.card.show = true
        this.card.msg = '请输入正确的手机号/身份证号码'
        return
      }
      if (this.time != 59) {
        return
      }
      MApi.getCode({
        card: this.form.card
      }).then(res => {
        if (res.data.code == 1) {
          this.time = 59
          let dateTime = setInterval(() => {
            this.codeMsg = this.time-- + 's'
            if (this.time == 0) {
              this.codeMsg = '获取验证码'
              this.time = 59
              clearInterval(dateTime)
            }
          }, 1000);
          this.$message.success("正在发送验证码")
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    onSubmit () {
      if (!this.checked) {
        this.$message.warning('请先阅读用户协议和隐私协议！')
        return;
      }
      if (this.form.card == '') {
        this.card.show = true
      } else {
        this.card.show = false
      }
      if (this.form.year == '') {
        this.year.show = true
      } else {
        this.year.show = false
      }
      if (this.form.code == '' && this.is_need == 1) {
        this.code.show = true
      } else {
        this.code.show = false
      }
      if (this.year.show || this.card.show || this.code.show) {
        return;
      }
      Api.login({
        card: this.form.card,
        year: this.form.year,
        yzm: this.form.code
      }).then(res => {
        if (res.data.code == 0) {
          this.$message.error('登录失败，请重新登录');
          return;
        }
        localStorage.setItem('year', this.form.year)
        localStorage.setItem('token', res.data.data.token)
        this.$store.commit('setToken', res.data.data.token)
        localStorage.setItem('card', this.form.card)
        this.getZyRoot()
      })
    },
    getUserInfo () {
      MApi.getUserInfo().then(res => {
        if (res.status == 200) {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          let is_ok = localStorage.getItem('is_ok')
          let statuts = this.form.year == 2024 ? (is_ok == 1 ? 0 : 1) : is_ok
          if (statuts == 0) {
            this.$router.push({name: 'Gongxu'})
          } else {
            if (res.data.xuanke == 1) {
              this.$router.push({name: 'Xuanke'})
            } else {
              this.$router.push({name: 'Gouke'})
            }
          }
        } else {
          this.$message.error("用户信息获取失败")
        }
      })
    },
    getZyRoot () {
      MApi.getZyRoot({}).then(res => {
        if (res.data.code == 1) {
          localStorage.setItem('is_ok', res.data.is_ok)
          this.getUserInfo()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleLink () {
      let url = localStorage.getItem("czsc")
      window.open(url)
    },
    getPrivacy (type) {
      this.$router.push({path: '/Privacy?type=' + type})
    }
  },
}
</script>

<style lang='less' scoped>
.login-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 425px;
  width: 350px;
  padding: 20px 0 20px;
  h3 {
    color: #fff;
  }
  .placeholder {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      color: #ccc;
      flex: 1;
    }
  }

  .login-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 20px 0 10px;
    position: relative;
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      border: 1px solid @default;
      padding: 10px 5px;
      background: #fff;
      max-width: 350px;
      &::after {
        content: '';
        width: 1px;
        height: 100%;
        background-color: @default;
        position: absolute;
        top: 0;
        left: 58px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      > span {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(0, 110%);
        color: red;
        display: inline-block;
        font-size: 14px;
      }
      img {
        width: 30px;
        margin: 0 10px;
      }
      > div {
        width: 80px;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        margin-right: 10px;
      }
      /deep/ .el-input__inner {
        border: none;
        padding-left: 15px;
        height: 30px;
        font-size: 16px;
      }
      /deep/ .el-input__icon {
        line-height: 30px;
      }
      input {
        border: none;
        outline: none;
        margin: 0 15px;
        font-size: 16px;
        flex: 1;
        max-width: 170px;
        height: 30px;
      }
      .picker {
        flex: 1;
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #D0CBCB; 
      }

      ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: #D0CBCB;
      }

      input:-ms-input-placeholder,
      textarea:-ms-input-placeholder {
        color: #D0CBCB;
      }

      input::-webkit-input-placeholder,
      textarea::-webkit-input-placeholder {
        color: #D0CBCB;
      }
    }
    .hide {
      height: 0px; 
      overflow: hidden; 
      padding: 0;
      border: 0; 
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  .pricavy {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    > p {
      border: 1px solid #999999;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      font-size: 26px;
      margin-right: 5px;
      cursor: pointer;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 12px;
      }
    }
    > p.checked {
      border-color: #06B487;
      background-color: #06B487;
      color: #ffffff;
    }
    > div {
      font-size: 14px;
      span {
        font-size: 14px;
        color: blue;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0px 0 20px;
    /deep/ .el-button span {
      font-size: 20px;
    }
  }

  .hint {
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    span {
      font-size: 20px;
      color: red;
      cursor: pointer;
    }
  }
}
</style>